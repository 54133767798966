import { clamp, interpolate, mixArray, progress } from '@popmotion/popcorn';
import { graphql } from 'gatsby';
import css from 'src/theme/css';
import Img from 'gatsby-image/withIEPolyfill';
import React, { useRef } from 'react';
import ColorProvider from 'src/components/ColorProvider';
import ImageGroup from 'src/components/ImageGroup';
import RichText from 'src/components/RichText';
import useAnimationFrame from 'src/hooks/useAnimationFrame';
import useBounds from 'src/hooks/useBounds';
import * as ease from 'src/utils/ease';
import { useStore } from 'src/components/GlobalState';

function fitRect(rect, bounds, reverse) {
  const sw = bounds[2] / rect[2];
  const sh = bounds[3] / rect[3];
  const scale = Math[reverse ? 'max' : 'min'](sw, sh);

  return [
    bounds[0] + (bounds[2] - rect[2] * scale) / 2,
    bounds[1] + (bounds[3] - rect[3] * scale) / 2,
    rect[2] * scale,
    rect[3] * scale,
  ];
}

function mapRect(rect, target) {
  return [
    target[0] + rect[0] * target[2],
    target[1] + rect[1] * target[3],
    rect[2] * target[2],
    rect[3] * target[3],
  ];
}

const padding = 0.1;

const ZoomModule = ({
  isHero,
  outerImage,
  innerImage,
  colorMode,
  hasMargin,
  hasScrim,
  innerX,
  innerY,
  innerW,
  innerH,
  text,
  ...other
}) => {
  const scrim = useRef();
  const layer = useRef();
  const reflow = useStore((state) => state.reflow);

  const outerSize = [
    outerImage.file.details.image.width,
    outerImage.file.details.image.height,
  ];

  const innerRect = [
    innerX / outerSize[0],
    innerY / outerSize[1],
    innerW / outerSize[0],
    innerH / outerSize[1],
  ];

  const paddingRect = [
    reflow.width * padding,
    reflow.height * padding,
    reflow.width * (1 - padding * 2),
    reflow.height * (1 - padding * 2),
  ];

  const a = fitRect([0, 0, outerSize[0], outerSize[1]], paddingRect);
  const b = mapRect(innerRect, a);
  const c = fitRect([0, 0, reflow.width, reflow.height], b);

  const maxScale = Math.max(reflow.width / c[2], reflow.height / c[3]);

  const [ref, bounds] = useBounds();
  useAnimationFrame(() => {
    if (layer.current && bounds.current) {
      const p = ease.inOutQuint(
        clamp(
          0,
          1,
          progress(reflow.height * 0.5, reflow.height * 2, -bounds.current.y)
        )
      );
      const [scale, x, y] = mixArray([maxScale, -c[0], -c[1]], [1, 0, 0])(p);
      layer.current.style.transform = `translate(${x}px, ${y}px) scale(${scale})`;
    }

    if (hasScrim && scrim.current) {
      const opacity = interpolate(
        [reflow.height * 0.5, reflow.height * 1],
        [1, 0]
      )(-bounds.current.y);
      scrim.current.style.opacity = opacity;
    }
  });

  return (
    <ColorProvider {...other} mode={colorMode}>
      <div
        css={css({
          position: 'relative',
          height: '300vh',
          mt: ({ space }) => (isHero ? `calc(${space.headerHeight} * -1)` : 0),
          mb: ({ space }) => `calc(${space.sectionMargin} - ${a[1]}px)`,
          zIndex: 0,
        })}
        ref={ref}
      >
        <div
          css={css({
            overflow: 'hidden',
            position: 'sticky',
            top: 0,
            left: 0,
          })}
        >
          <div
            ref={layer}
            css={css({
              position: 'relative',
              height: '100vh',
              width: '100vw',
              transformOrigin: `${c[0]}px ${c[1]}px`,
            })}
          >
            <Img
              {...outerImage}
              css={css({
                position: 'absolute !important',
                left: `${a[0]}px`,
                top: `${a[1]}px`,
                width: `${a[2]}px`,
                height: `${a[3]}px`,
              })}
            />
            <ImageGroup
              {...innerImage}
              css={css({
                position: 'absolute',
                left: `${b[0]}px`,
                top: `${b[1]}px`,
                width: `${b[2]}px`,
                height: `${b[3]}px`,
              })}
            />
          </div>
          <div
            ref={scrim}
            css={css({
              display: hasScrim ? 'block' : 'none',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100vh',
              background: ({ colors, mode }) =>
                `linear-gradient(
                to ${mode === 'dark' ? 'bottom' : 'top'},
                ${colors.antiModeAlpha[2]},
                ${colors.antiModeAlpha[8]})`,
            })}
          />
        </div>
        <div
          css={css({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
          })}
        >
          <RichText
            alignItems="center"
            textAlign="center"
            justifyContent="center"
            css={css({
              minHeight: '100vh',
              maxWidth: '70rem',
              mx: 'auto',
              px: 'pageMargin',
              pb: 'sectionMargin',
              pt: ({ space }) =>
                isHero
                  ? `calc(${space.sectionMargin} + ${space.headerHeight})`
                  : 'sectionMargin',
            })}
            {...text}
          />
        </div>
      </div>
    </ColorProvider>
  );
};

export default ZoomModule;

export const query = graphql`
  fragment ZoomModuleFragment on ContentfulZoomModule {
    id
    slug
    colorMode
    hasScrim
    outerImage {
      fluid(maxWidth: 2880, quality: 90) {
        ...GatsbyContentfulFluid_withWebp
      }
      file {
        details {
          image {
            width
            height
          }
        }
      }
    }
    innerImage {
      ...ImageGroupFragment
    }
    innerX
    innerY
    innerW
    innerH
    text {
      json
    }
  }
`;
